import { useCallback } from 'react'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useMiniWishListContext } from '@bluheadless/ui-logic/src/providers/mini-wish-list'
import { useUser } from '@bluheadless/ui-logic/src/providers/user'
import MenuItem from '@mui/material/MenuItem'
import { cx } from '@emotion/css'
import { FormattedMessage, useIntl } from 'react-intl'
import AccountIcon from '../../atoms/icons/account-icon'
import HeartIcon from '../../atoms/icons/heart-icon'
import SupportIcon from '../../atoms/icons/support-icon'
import Link from '../../molecules/link'
import { ChangeCountryLanguage } from '../../organisms/change-country-language'
import { ButtonIcon, Root, StorelocatorLinkStyled } from './service-links.styled'
import { PAGE_PATH_CUSTOMER, PAGE_PATH_LOGIN } from '@bluheadless/ui-logic/src/constants'

const ServiceLinks = ({ className, ...props }) => {
	const {
		contacts,
		wishList: { enabled: wishListEnabled },
	} = useConfig()
	const [{ isLoggedIn }] = useUser()
	const { setOpen: setOpenMiniWishList } = useMiniWishListContext()
	const handleWishlistClick = useCallback(() => {
		setOpenMiniWishList(true)
	}, [setOpenMiniWishList])
	const { formatMessage } = useIntl()

	return (
		<Root className={cx('ServiceLinks-root', className)} {...props}>
			<MenuItem>
				<Link underline="none" href={isLoggedIn ? PAGE_PATH_CUSTOMER : PAGE_PATH_LOGIN}>
					<ButtonIcon
						badgeProps={isLoggedIn ? { color: 'success', variant: 'dot' } : undefined}
						Icon={AccountIcon}
						variant="icon"
					/>

					<FormattedMessage id="sidebar_navigation_account" />
				</Link>
			</MenuItem>
			{wishListEnabled && (
				<MenuItem>
					<Link underline="none" onClick={handleWishlistClick}>
						<HeartIcon fontSize="middle" />
						<FormattedMessage id="sidebar_navigation_wishlist" />
					</Link>
				</MenuItem>
			)}
			{contacts.enabled && (
				<MenuItem>
					<Link underline="none" href={contacts.path}>
						<SupportIcon fontSize="middle" />
						<FormattedMessage id="sidebar_navigation_customer_care" />
					</Link>
				</MenuItem>
			)}
			<MenuItem>
				<StorelocatorLinkStyled
					className="storelocator-link"
					buttonProps={{
						StartIcon: null,
						variant: 'textLink',
						color: 'primary',
						underline: 'none',
						iconProps: { fontSize: 'medium' },
						label: <FormattedMessage id={'footer_store_locator_link_label'} />,
					}}
				/>
			</MenuItem>
			<MenuItem>
				<ChangeCountryLanguage
					className="change-country-language-trigger-sidebar"
					customLabel={formatMessage({ id: 'change_country_label' })}
					showLanguage={false}
					showFlag={false}
					showCurrencySymbol={false}
					showArrowDown={false}
				/>
			</MenuItem>
		</Root>
	)
}

export default ServiceLinks

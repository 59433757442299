/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const AccountIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
			<path
				d="M19.314 20.229V18.4a3.657 3.657 0 00-3.657-3.657H8.343A3.658 3.658 0 004.686 18.4v1.829M12 11.086a3.657 3.657 0 100-7.314 3.657 3.657 0 000 7.314z"
				strokeWidth={1.37142}
			/>
		</g>
	</svg>
))
AccountIconSvg.displayName = 'AccountIconSvg'

const AccountIcon = forwardRef((props, ref) => <Icon component={AccountIconSvg} ref={ref} {...props} />)
AccountIcon.displayName = 'AccountIcon'

AccountIcon.defaultProps = {
	...Icon.defaultProps,
}
AccountIcon.propTypes = {
	...Icon.propTypes,
}

export default AccountIcon
export { AccountIconSvg }

/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const HeartIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M20.422 9.358c0 1.303-.5 2.554-1.393 3.48-2.056 2.13-4.05 4.352-6.182 6.406a1.232 1.232 0 01-1.732-.038L4.97 12.837c-1.857-1.925-1.857-5.033 0-6.958a4.7 4.7 0 016.805 0l.224.232.223-.231a4.727 4.727 0 013.403-1.459c1.28 0 2.504.526 3.403 1.458a5.011 5.011 0 011.393 3.48z"
			stroke="currentColor"
			strokeLinejoin="round"
			fill="none"
			strokeWidth={0.842}
		/>
	</svg>
))
HeartIconSvg.displayName = 'HeartIconSvg'

const HeartIcon = forwardRef((props, ref) => <Icon component={HeartIconSvg} ref={ref} {...props} />)
HeartIcon.displayName = 'HeartIcon'

HeartIcon.defaultProps = {
	...Icon.defaultProps,
}
HeartIcon.propTypes = {
	...Icon.propTypes,
}

export default HeartIcon
export { HeartIconSvg }

import { PAGE_PATH_HOME } from '@bluheadless/ui-logic/src/constants'
import Image from '@bluheadless/ui/src/particles/image'
import Link from '@bluheadless/ui/src/molecules/link'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import IconClose from '@bluheadless/ui/src/atoms/icons/x-icon'
import {
	ButtonClose,
	MiniHeaderStyled,
} from '@bluheadless/ui/src/organisms/sidebar-navigation//sidebar-navigation.styled'

const MiniHeader = ({ buttonCloseProps, setToggleSidebar }) => {
	const {
		logo: {
			light: { image: logoLightSrc, width: logoLightWidth, height: logoLightHeight },
		},
	} = useConfig()

	return (
		<MiniHeaderStyled>
			<Link className="logo" href={PAGE_PATH_HOME}>
				<Image originalHeight={logoLightHeight} originalWidth={logoLightWidth} alt="" title="" src={logoLightSrc} />
			</Link>
			<ButtonClose
				variant="icon"
				Icon={IconClose}
				iconProps={{ fontSize: 'medium' }}
				className="iconClose"
				color="black"
				onClick={() => setToggleSidebar(false)}
				{...buttonCloseProps}
			/>
		</MiniHeaderStyled>
	)
}

export default MiniHeader

import { styled } from '@mui/system'
import Button from '../button'

const ButtonStyled = styled(Button)`
	.MuiBadge-root {
		.MuiBadge-badge {
			font-size: ${({ theme }) => theme.typography.pxToRem(10)};
			width: 10px;
			height: 10px;
		}
		> .MuiSvgIcon-root {
			color: ${({ theme }) => theme.palette.primary.main};
			font-size: ${({ theme }) => theme.typography.pxToRem(20)};
		}
		.MuiBadge-badge .MuiSvgIcon-root {
			color: ${({ theme }) => theme.palette.primary.contrastText};
			font-size: ${({ theme }) => theme.typography.pxToRem(18)};
		}
	}
`

export { ButtonStyled }

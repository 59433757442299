/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Menu1IconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
			<path d="M2.4 4.533h19.2M2.4 12h19.2M2.4 19.467h19.2" strokeWidth={1.06667} />
		</g>
	</svg>
))
Menu1IconSvg.displayName = 'Menu1IconSvg'

const Menu1Icon = forwardRef((props, ref) => <Icon component={Menu1IconSvg} ref={ref} {...props} />)
Menu1Icon.displayName = 'Menu1Icon'

Menu1Icon.defaultProps = {
	...Icon.defaultProps,
}
Menu1Icon.propTypes = {
	...Icon.propTypes,
}

export default Menu1Icon
export { Menu1IconSvg }
